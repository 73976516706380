
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































































































@import './src/styles/base/spacing';

.section {
  position: relative; // Required for navigation to stick
  padding-top: 0;
  background-color: $c-surface-light-accent;
}

.navigation-sticky-wrapper {
  position: sticky;
  top: 0;
  z-index: 1;

  @include mq($from: s) {
    position: relative;
  }
}

@include mq($from: s) {
  .header-is-stuck {
    .navigation-sticky-wrapper {
      top: $header-height;
    }
  }
}

.navigation {
  display: flex;
  white-space: nowrap;

  &__item {
    --progress: 0;

    position: relative;
    font-size: 1.5rem;
    font-weight: 600;
    white-space: nowrap;
    background-color: $c-surface-light-accent;

    @include mq($from: l) {
      min-width: 300px; // Arbitrary value
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0.4rem;
      background-color: $c-main;
      transform-origin: 0 0;
      transform: scaleX(var(--progress));
    }

    &:not(:last-of-type) {
      padding-right: 10rem;
    }

    &.active {
      .navigation__button {
        opacity: 1;
      }
    }
  }

  &__button {
    padding: 2rem 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    opacity: 0.4;
  }
}

.prev-next {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5rem;

  @include mq($until: xs) {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @include mq($from: xs) {
    margin-bottom: 0;
  }

  &__btn {
    background: transparent;
    border-width: 0;
    cursor: pointer;
    padding: 0;

    &:not(:last-of-type) {
      margin-right: clamp(2.5rem, 2.976vw, 5rem);
    }

    &[disabled] {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  &__icon {
    width: clamp(5rem, 4.88vw, 8.2rem);
    height: clamp(2.3rem, 2.083vw, 3.5rem);
  }
}

.slide-component {
  .item {
    display: none;

    &.active {
      display: unset;
    }
  }
}

.slide-title {
  font-size: clamp(3rem, 4.761vw, 8rem);
  font-weight: 600;
  line-height: 1.2;
}

::v-deep {
  .wysiwyg {
    // Fix text-decoration on text splitted by words
    a {
      text-decoration: none;

      div {
        text-decoration: underline;
      }
    }
  }
}
