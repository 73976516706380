
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        































































::v-deep {
  .intro {
    display: none;

    @include mq($from: s) {
      display: flex;
    }
  }

  .solution-title {
    background-color: $c-surface-dark;
  }

  .solutions-listing {
    .solution {
      position: relative;
      margin-bottom: clamp(13.5rem, 21.666vw, 36.4rem);

      &__title {
        @include mq($from: xl) {
          margin-left: -9rem;
        }

        @include mq($from: xxl) {
          margin-left: -10.5rem;
        }

        @include mq($from: xxxl) {
          margin-left: -11.5rem;
        }
      }

      .visual-container {
        position: relative;

        @include mq($until: m) {
          max-width: 63rem;
          margin: 0 auto clamp(12rem, 30vw, 20rem) auto;
        }
      }

      .visual,
      [class*='visual--'] {
        &[class*='primary'] {
          width: 100%;
        }

        &[class*='secondary'] {
          position: absolute;
          object-fit: cover;
          top: 81.981%;
          left: 45.645%;
          width: 44.144%;
          height: 53.153%;

          @include mq($from: m) {
            top: 81.6%;
            left: 70%;
            width: 40.47%;
            height: 48.19%;
          }
        }
      }

      &:nth-of-type(even) {
        > .row {
          flex-direction: row-reverse;
          justify-content: flex-end;
        }

        .visual--secondary {
          left: 14.414%;

          @include mq($from: m) {
            left: unset;
            right: 70%;
          }
        }
      }

      > .row {
        position: relative;
      }
    }
  }
}

.solutions-listing::v-deep {
  .solution-title {
    background-color: $c-surface-light;

    &::after {
      left: 12.5%;
      width: 37.5%;
    }
  }

  .solution {
    &:nth-of-type(even) {
      .solution-title {
        &::after {
          width: 78%;
          transform-origin: 0% 0%;
        }
      }
    }
  }
}
