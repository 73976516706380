
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















































































.expand-toggle__plus {
  transition: none;
}

.expand-toggle {
  --progress: 0;

  @media (prefers-reduced-motion) {
    --progress: 1;
  }

  &::before {
    transform-origin: 0 0;
    transform: scaleX(var(--progress));
  }
}

.list-item--depth-1 {
  .expand-toggle {
    --progress: 1;
  }
}
