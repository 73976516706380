
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        













































































































































































































































































































.form-close {
  width: 4rem;
  margin-right: 8.3333333333%;
  padding: 0;
  background: transparent;
  border-width: 0;
  cursor: pointer;
}

.main-service-title {
  @include liquid(font-size, 20px, 40px);

  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 600;
}

.contact-baseline {
  @include liquid(font-size, 15px, 20px);
  @include liquid(margin-bottom, 40px, 46px);

  color: $c-main;
  font-weight: 400;
}

.hr {
  @include liquid(margin-bottom, 15px, 35px);

  margin-top: 0;
}

.address__title {
  margin-bottom: 2rem;
}

.bleed-section {
  position: relative;
  z-index: 1;
  padding-bottom: 0;
}

.bleed-section__background-container {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);

  @include mq($until: wrapper) {
    overflow: hidden;
  }
}

.contact-title::v-deep {
  @include liquid(font-size, 20px, 40px);

  margin-top: 0;
  margin-bottom: 3rem;
  line-height: 1.25;
  font-weight: 600;
}

::v-deep {
  img.hero__visual {
    --scale: 1.2;
    --translateY: 0;

    opacity: 0.6;
    transform: scale(var(--scale)) translateY(var(--translateY));
  }

  .service {
    .curtain {
      width: 100%;
    }
  }

  .other-service {
    .contact-title {
      @include liquid(margin-bottom, 5px, 10px);
    }

    .wysiwyg {
      .small {
        line-height: 1.6;

        @include mq($until: s) {
          margin-bottom: 2.8rem;
        }
      }

      strong {
        opacity: 0.4;
      }
    }
  }

  .wysiwyg {
    @include liquid(font-size, 15px, 20px);

    line-height: 1.5;

    p {
      margin-bottom: 1rem;
    }

    a {
      &[href^='mailto:'],
      &[href^='tel:'] {
        text-decoration: none;
      }
    }
  }

  .address {
    font-style: initial;

    p {
      &:not(:last-of-type) {
        margin-bottom: 2em;
      }
    }
  }

  .bleed {
    background-color: $c-surface-light-accent;
  }
}
