
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














.accordion {
  --height: 0;
  --duration: 1s;

  overflow: hidden;
  height: 0;
  transition: height var(--duration) $ease-in-quad;

  &.expanded {
    height: var(--height);
    transition-timing-function: $ease-out-quad;
  }
}
