
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























.float-label__label {
  pointer-events: none; // Not great a11y but we would like to open the dropdown when clicking label...
}
