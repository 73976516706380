
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















.textarea-container {
  display: flex;
  flex-direction: column-reverse;
}
