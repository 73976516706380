
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















.collapse-trigger {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  background: transparent;
  border-width: 0;

  &:active {
    color: currentColor; // Safari use white if not explicitely defined...
  }
}

.collapse-content {
  overflow: hidden;
  transition: height 1s $easing;
}
