
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









































::v-deep {
  .list-item,
  [class*='list-item--'] {
    &[class*='--depth-0'] {
      .button__label {
        font-family: $ff-alt;
        font-size: clamp(2rem, 2.38vw, 4rem);
        line-height: 2.225;
        text-transform: uppercase;
        letter-spacing: 0.1em;
      }
    }

    &[class*='--depth-1'] {
      .button__label {
        font-family: $ff-default;
        font-size: clamp(2rem, 1.785vw, 3rem);
        line-height: 1.36;
        text-transform: unset;
        letter-spacing: unset;
      }
    }

    &[class*='--depth-2'] {
      .label {
        margin-bottom: 2rem;
        color: $c-main;
        font-size: 1.5rem;
        line-height: 1.8rem;

        @include mq($from: m) {
          text-align: right;
          margin-bottom: 0;
        }
      }
    }
  }

  .content-expended {
    .button__plus {
      transform: rotate(45deg);
    }
  }
}
