
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































@import './src/styles/base/spacing';

.product-list__item {
  &:first-of-type {
    .product {
      padding-top: 0;
    }
  }

  &:last-of-type {
    @include liquid(margin-bottom, 30px, 100px);
  }
}

.product {
  @include liquid(padding-top, 14px, 24px);
  @include liquid(padding-bottom, 14px, 24px);

  position: relative;
  display: flex;
  align-items: center;
  font-weight: 600;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    opacity: 0.2;
  }
}

.product__label {
  margin-right: auto;
  font-size: 1.5rem;
  line-height: 1.36;
}

.product__download {
  font-size: 13px;
  line-height: 1;
  margin-left: 1.3rem;

  &.product__svg {
    width: 2.4rem;
    height: 2.4rem;
  }
}
