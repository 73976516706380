
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














.flexible {
  ::v-deep div:first-child {
    margin-top: 0;
  }
}
