
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















































.input {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-width: 0;
  clip-path: inset(100% 100% 0 0);
}

.label {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 4rem;
  margin: 0 0 2rem;
  cursor: pointer;
  line-height: 2; // Override default line-height for this specific input

  @include mq($from: xs) {
    flex-direction: row;
  }
}

.label__float-container {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 2rem;
  border-bottom: 2px currentColor solid;

  @include mq($from: xs) {
    @include liquid(margin-right, 20px, 50px);

    margin-bottom: 0;
  }
}

.label__float {
  transform-origin: 0 50%;
  display: block;
  white-space: nowrap;
  opacity: 1;
  transition: all $transition-duration $easing;
  transform: translateY(0%) scale(1);

  &.floating {
    transform: translateY(-60%) scale(0.75);
    opacity: 0.4;
  }
}

.file-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  font-size: 2rem;
  line-height: 2;
  pointer-events: none;
}

.file-list__item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.instructions-list {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.5rem;
  opacity: 0.4;
}

.instructions-list__item {
  margin-right: 2rem;
  margin-bottom: 1rem;
}

::v-deep {
  .upload-button {
    .curtain__inner {
      @include liquid(padding-right, 20px, 40px);
      @include liquid(padding-left, 20px, 40px);

      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
}
